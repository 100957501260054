<h1 mat-dialog-title>
    {{ data?.payment ? 'Editar' : 'Registrar' }} condição de pagamento
</h1>

<p *ngIf="isSpecial"
   class="mat-card-subtitle">
    Esta é uma condição de pagamento especial, não pode ser editada.
</p>

<mat-card-content [class.mat-card-content-mobile]="isMobile">
    <ng-container *ngIf="!isMobile; else mobileTemplate">
        <form autocomplete="off"
              [formGroup]="form">

            <!-- descrição -->
            <mat-form-field class="col-3">
                <mat-label>Descrição</mat-label>
                <input matInput
                        formControlName="description"
                        (input)="capitalize()">
                <mat-error *ngIf="form.get('description').errors">
                    {{ form.get('description').errors | error | translate : form.get('description').errors }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-2">
                <mat-label>Condição</mat-label>
                <mat-select formControlName="condition">
                    <mat-option *ngFor="let item of PAYMENT_FORMS"
                                [value]="item">
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-3">
                <mat-label>Forma</mat-label>
                <mat-select formControlName="paymentForm">
                    <mat-option *ngFor="let key of paymentMethodsKeys"
                                [title]="('ELEMENT.PAYMENT_METHOD.' + key) | translate"
                                [value]="key | paymentMethod">
                        {{ ('ELEMENT.PAYMENT_METHOD.' + key) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-2">
                <mat-label>Parcelas</mat-label>
                <input matInput
                        formControlName="installmentsAmount"
                        money
                        [decimalPoints]="0"
                        maxlength="2"
                        (input)="onInputInstallmentsAmount()">
            </mat-form-field>

            <div class="mat-form-field col-2">
                <mat-slide-toggle formControlName="status"
                                    color="primary">
                    {{ form?.value?.status ?  'Ativo': 'Inativo' }}
                </mat-slide-toggle>
            </div>

        </form>
    </ng-container>

    <ng-template #mobileTemplate>
        <form autocomplete="off"
              [formGroup]="form">

            <!-- descrição -->
            <div class="form-group">
                <label>Descrição</label>
                <input matInput
                        formControlName="description"
                        (input)="capitalize()">
                <mat-error *ngIf="form.get('description').errors">
                    {{ form.get('description').errors | error | translate : form.get('description').errors }}
                </mat-error>
            </div>

            <div class="form-group">
                <label>Condição</label>
                <mat-select formControlName="condition" class="select">
                    <mat-option *ngFor="let item of PAYMENT_FORMS"
                                [value]="item">
                        {{ item }}
                    </mat-option>
                </mat-select>
            </div>

            <div class="form-group">
                <label>Forma</label>
                <mat-select formControlName="paymentForm" class="select">
                    <mat-option *ngFor="let key of paymentMethodsKeys"
                                [title]="('ELEMENT.PAYMENT_METHOD.' + key) | translate"
                                [value]="key | paymentMethod">
                        {{ ('ELEMENT.PAYMENT_METHOD.' + key) | translate }}
                    </mat-option>
                </mat-select>
            </div>

            <div class="form-group">
                <label>Parcelas</label>
                <input matInput
                        formControlName="installmentsAmount"
                        money
                        [decimalPoints]="0"
                        maxlength="2"
                        (input)="onInputInstallmentsAmount()">
            </div>

            <div class="form-group">
                <mat-slide-toggle formControlName="status"
                                    color="primary">
                    {{ form?.value?.status ?  'Ativo': 'Inativo' }}
                </mat-slide-toggle>
            </div>
        </form>
    </ng-template>

    <table mat-table
            *ngIf="installments.data.length"
            [dataSource]="installments"
            class="w-100 mh-80 overflow-auto">

        <ng-container matColumnDef="seq">
            <th mat-header-cell
                *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let row">
                {{ row.seq }}
            </td>
        </ng-container>

        <ng-container matColumnDef="percentage">
            <th mat-header-cell
                *matHeaderCellDef> Porcentagem do valor </th>
            <td mat-cell
                *matCellDef="let row">
                <!-- <mat-form-field class="col-12"> -->
                <input matInput
                        [(ngModel)]="row.percentage"
                        autocomplete="off"
                        [disabled]="isSpecial"
                        (input)="onInputPercentage(row)"
                        placeholder="Porcentagem"
                        money
                        [decimalPoints]="PARCEL_PERCENTAGE_DECIMAL_POINTS">
                <!-- </mat-form-field> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="days">
            <th mat-header-cell
                *matHeaderCellDef> Dias </th>
            <td mat-cell
                *matCellDef="let row">

                <!-- <mat-form-field class="col-12"> -->
                <input matInput
                        [(ngModel)]="row.days"
                        money
                        [decimalPoints]="0"
                        autocomplete="off"
                        [disabled]="isSpecial"
                        (input)="onChanged()"
                        placeholder="Dias">
                <!-- </mat-form-field> -->

            </td>
        </ng-container>


        <tr mat-header-row
            *matHeaderRowDef="columns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: columns;"></tr>
    </table>

    <mat-hint *ngIf="installments.data.length && !sum100">
        <small>
            A soma das porcentagens deve ser igual a 100.
        </small>
        <a mat-button
           (click)="divideSum()"
           *ngIf="!isMobile">
           <small>
               Dividir automaticamente
           </small>
        </a>
        <button *ngIf="isMobile" class="button w-100" (click)="divideSum()">
            Dividir automaticamente
        </button>
    </mat-hint>
    <mat-hint *ngIf="installments.data.length && sum100 && !hasDate">
        <small>Insira os dias de prazo.</small>
    </mat-hint>

    <!-- <a mat-button
    *ngIf="installments.data.length"
    color="primary"
    class="align-end">
    Clique aqui para autocompletar
    </a> -->

    <mat-paginator #intallmentsPaginator
                [hidden]="installments.data.length === 0"
                [pageSize]="settings.config.pagination.pageSize"
                [pageSizeOptions]="settings.config.pagination.pageSizeOptions"
                [showFirstLastButtons]="settings.config.pagination.showFirstLastButtons">
    </mat-paginator>
</mat-card-content>

<mat-dialog-actions align="end" *ngIf="!isMobile; else mobileActionButtonsTemplate">
    <button mat-raised-button
            (singleClick)="close(false)"
            color="warn">Cancelar</button>
    <button mat-raised-button
            color="primary"
            [disabled]="form.invalid || !sum100 || !hasDate"
            (singleClick)="close(true)">OK</button>
</mat-dialog-actions>
<ng-template #mobileActionButtonsTemplate>
    <mat-dialog-actions class="d-flex justify-content-center gap-1 align-items-center">
      <button class="button mx-0 bg-red"
              (singleClick)="close(false)"
              aria-label="cancel"
              type="button"
              color="warn">Cancelar</button>
      <button class="button mx-0"
              aria-label="save"
              type="button"
              [disabled]="form.invalid || !sum100 || !hasDate"
              (singleClick)="close(true)">OK</button>
    </mat-dialog-actions>
</ng-template>