import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { Bank } from '../interface/bank';
import { environment } from '../../environments/environment';
import { DataService } from './data.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  private _isFromMatrix = false;


  constructor(
    private _http: HttpClient,
    private dataService: DataService
  ) { }

  public async create(bank: Bank) {
    const url = `${environment.mkgoURL}/api/v1/bank`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const apiBank = bank.toAPI()
    const resp: any = await this._http.post(url, bank.toAPI(), options).pipe(first()).toPromise();
    return resp;
  }

  public async getAll(includeInactives = false): Promise<Bank[]> {
    const url = `${environment.mkgoURL}/api/v1/bank`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.get(url, options).pipe(first(), map((r: any) => r.banks)).toPromise() as any[];
    const banks: Bank[] = [];
    if (resp && Array.isArray(resp)) {
      for (const obj of resp) {
        if(obj.available || includeInactives){
          banks.push(new Bank(obj))
        }
      }
      return banks;
    }
    return [];
  }

  /** @todo test */
  public async getById(id: string): Promise<Bank> {
    if (!id) {
      throw new Error(`ID do banco inválido: ${id}`);
    }
    const url = `${environment.mkgoURL}/api/v1/bank/${id}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.get(url, options).pipe(first(), map(r => new Bank(r))).toPromise();
    return resp;
  }

  public async update(bank: Bank) {
    if (!bank._id) {
      throw new Error("o ID do banco não foi informado, impossível atualizar");
    }
    const url = `${environment.mkgoURL}/api/v1/bank/${bank._id}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.put(url, bank.toAPI(), options).pipe(first()).toPromise();
    return resp;
  }

  /** @unused */
  public async delete(id: string) {
    if (!id) {
      throw new Error(`ID do banco inválido: ${id}`);
    }
    const url = `${environment.mkgoURL}/api/v1/bank/${id}`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this._http.delete(url, options).pipe(first()).toPromise();
    return resp;
  }

}
