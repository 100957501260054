export class Bank {
    _id?: string;
    code: string;
    name: string;
    operation: string;
    agencia: string;
    account: string;
    digito: string;
    description?: string;
    private _saldo: number;

    constructor(apiObj: any) {
        this.code = apiObj.code;
        this.name = apiObj.name;
        this.operation = apiObj.operation;
        this.agencia = apiObj.agencia;
        this.account = apiObj.account;
        this.digito = apiObj.digito;
        this.saldo = apiObj.saldo;

        if (apiObj._id) {
            this._id = apiObj._id;
        }
        if (apiObj.description) {
            this.description = apiObj.description;
        }
    }

    public get saldo(): number {
        return this._saldo;
    }

    public set saldo(value: number | undefined) {
        this._saldo = Number(value ? value.toFixed(2) : 0);
    }

    public toAPI() {
        let apiObj = {
            code: this.code,
            name: this.name,
            operation: this.operation,
            agencia: this.agencia,
            account: this.account,
            digito: this.digito,
            saldo: this.saldo
        }
        if (this._id) {
            apiObj['_id'] = this._id;
        }
        if (this.description) {
            apiObj['description'] = this.description;
        }
        return apiObj;
    }
}
