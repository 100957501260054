import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Utilities } from '../../../class/utilities';
import { Parcel } from '../../../interface/parcel';
import { PaymentCondition } from '../../../interface/paymentCondition';
import { ConfigService } from '../../../service/config.service';
import { LayoutService } from '../../../service/layout.service';
import { PaymentService } from '../../../service/payment.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { PAYMENT_FORMS } from '../../../shared/lists/payment-forms';
import { PAYMENT_METHODS } from '../../../shared/lists/payment-methods';

@Component({
  selector: 'app-payment-method-edit',
  templateUrl: './payment-method-edit.component.html',
  styleUrl: './payment-method-edit.component.scss'
})
export class PaymentMethodEditComponent implements OnInit {

  @ViewChild('intallmentsPaginator', { static: false }) paginator: MatPaginator;
  PARCEL_PERCENTAGE_DECIMAL_POINTS = 6;
  PAYMENT_FORMS = PAYMENT_FORMS;
  paymentMethodsKeys = Object.keys(PAYMENT_METHODS);
  columns = ['seq', 'percentage', 'days'];
  sum100: boolean = false;
  hasDate: boolean = false;
  isSpecial: boolean = false;

  isMobile: boolean;

  form: FormGroup = this.formBuilder.group({
    description: '',
    condition: '',
    paymentForm: '',
    installmentsAmount: '',
    status: true
  });

  installments = new MatTableDataSource<Parcel>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { payment: PaymentCondition, isMobile: boolean },
    public settings: ConfigService,
    private dialogRef: MatDialogRef<PaymentMethodEditComponent>,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private snacbar: SnackbarService,
    private layout: LayoutService
  ) { }

  ngOnInit() {
    this.getData();
    this.isMobile = this.data.isMobile;
  }

  async getData() {
    const allPayments = await this.paymentService.getAll();
    const allPaymentsDescriptions = allPayments.map(payment => payment.description)
    this.buildForm(allPaymentsDescriptions);
  }

  private buildForm(allDescriptions: string[]) {
    const payment = this.data ? this.data.payment : undefined;
    this.isSpecial = payment && payment.description === '999';
    this.form = this.formBuilder.group({
      description: [{
        value: payment ? payment.description : '',
        disabled: this.isSpecial
      }, [Validators.required, Utilities.unique((payment ? payment.description : ''), allDescriptions)]],
      condition: [{
        value: payment ? payment.condition : '',
        disabled: this.isSpecial
      }, Validators.required],
      paymentForm: [{
        value: payment ? payment.form : '',
        disabled: this.isSpecial
      }, Validators.required],
      installmentsAmount: [{
        value: payment ? payment.parcels.length : '',
        disabled: this.isSpecial
      }, Validators.compose([Validators.required, Validators.min(1)])],
      status: [{
        value: (payment ? Boolean(payment.status) : true),
        disabled: this.isSpecial
      }]
    });

    if (payment) {
      this.setTable(payment.parcels);
      this.onChanged();
    }

  }

  onInputInstallmentsAmount() {
    this.sum100 = false;
    const value = this.form.get('installmentsAmount').value;
    if (!value) {
      this.setTable()
      return
    }
    if (value && value < 100) {
      let table = [];
      let idx = 0;
      while (idx < value) {
        table.push({
          seq: idx + 1,
          percentage: '',
          days: '',
        })
        idx++;
      }

      if(value === 1){
        table[0]['percentage'] = 100;
        this.sum100 = true;
        if (this.form.get('condition').value === PAYMENT_FORMS[0]){
          table[0]['days'] = 0;
          this.hasDate = true;
        }
      }

      this.setTable(table)
    }
  }

  private setTable(data?: Parcel[]) {
    this.installments = new MatTableDataSource(data ? data : []);
    setTimeout(() => {
      this.installments.paginator = this.paginator;
    });
  }

  onInputPercentage(parcel: Parcel) {
    let others = this.installments.data.slice();
    const index = others.indexOf(parcel);
    others.splice(index, 1);
    let sum = 0;
    others.forEach(installment => {
      sum += installment.percentage || 0
    });
    const max = 100 - sum;
    const value = Math.min((parcel.percentage || 0), max);
    setTimeout(() => {
      parcel.percentage = value;
      this.onChanged();
    });
  }

  onChanged() {
    let sum = 0;
    let hasDate = true;
    for (const installment of this.installments.data) {
      sum += (installment.percentage || 0);
      if (!Number.isFinite(installment.days)) {
        hasDate = false;
      }
    }
    this.hasDate = hasDate;
    this.sum100 = (sum == 100);
  }

  /** @todo move to PaymentService */
  private assemblePayment() {
    let paymentCondition: PaymentCondition = {
      // company: this.dataService.company.id,
      description: this.form.get('description').value,
      condition: this.form.get('condition').value,
      form: this.form.get('paymentForm').value,
      status: this.form.get('status').value ? 1 : 0,
      parcels: this.installments.data
    }
    if (this.data && this.data.payment) {
      paymentCondition['_id'] = this.data.payment._id;
    }
    return paymentCondition
  }

  async close(save: boolean) {
    if (save && !this.isSpecial) {
      let paymentCondition = this.assemblePayment();
      const isNew = !Boolean(paymentCondition._id)
      try {
        this.layout.loader = true;
        const id = await this.paymentService[isNew ? 'create' : 'update'](paymentCondition)
        paymentCondition['_id'] = id;
        this.dialogRef.close(paymentCondition)
        this.snacbar.success(isNew ? "PAYMENT_CONDITION.ADDED" : "PAYMENT_CONDITION.UPDATED");
      } catch (error) {
        this.snacbar.error(isNew ? "PAYMENT_CONDITION.NOT_ADDED" : "PAYMENT_CONDITION.NOT_UPDATED");
        console.error(error)
      } finally {
        this.layout.loader = false;
      }
    } else {
      this.dialogRef.close(false)
    }
  }

  capitalize() {
    let value = this.form.get('description').value as string;
    if (value.length) {
      value = value[0].toUpperCase() + value.substring(1);
      this.form.get('description').setValue(value);
    }
  }

  public divideSum() {
    const parcels = this.installments.data.length;
    const percent = Number((100 / parcels).toFixed(this.PARCEL_PERCENTAGE_DECIMAL_POINTS));

    for (let i = 0; i < this.installments.data.length; i++) {
      const installment = this.installments.data[i];
      installment.percentage = percent;
      if(!Number.isFinite(installment.days)){
        installment.days = 30 * (i + 1);
      }
    }

    let sum = this.installments.data.slice().map(inst => inst.percentage).reduce((a, b) => a + b);
    this.installments.data[0].percentage += 100 - sum;

    this.onChanged()
  }

}
